// App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Main from './pages/main';
import Home from './portal/home';
import Login from "./portal/auth/Login";

const App = () => {
  const [user, setUser] = useState({});
  useEffect(() => {
    const theUser = localStorage.getItem("user");

    if (theUser && !theUser.includes("undefined")) {
      setUser(JSON.parse(theUser));
    }
  }, []);

  return (
    <BrowserRouter>
     <Routes>
        <Route
          path="/"
          element={user?.email ? <Navigate to="/portal/home" /> : <Login/>}
          />
        <Route
          path="/home"
          element={user?.email ? <Home user={user} /> : <Navigate to="/" />}
          />
        </Routes>
    </BrowserRouter>
  );
};

export default App;

// import React from 'react';
// import Grid from '@mui/material/Grid';
// import './App.css';
// import Router from './Router';

// function App() {
//   const ellipsisStyle = {
//     position: 'absolute',
//     top: '8%',
//     left: '50%',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     padding: '1rem'
//   };
//   // const isMobile = navigator?.userAgentData?.mobile || true;

//   return (
//     <Grid className="App" style={ellipsisStyle} container>
//       <Router/>
//     </Grid>
//   );
// }

// export default App;
